
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_not-found",
      function () {
        return require("next/dist/client/components/not-found-error");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_not-found"])
      });
    }
  